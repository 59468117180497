/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/styles/main.css"

function addScript(src, id) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = src
        if (!!id) script.id = id
        script.async = true
        script.type = "text/javascript"
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    })
}

function addStyle(src, id) {
    return new Promise((resolve, reject) => {
        const style = document.createElement("link")
        style.href = src
        if (!!id) style.id = id
        style.async = true
        style.rel = "stylesheet"
        style.onload = resolve
        style.onerror = reject
        document.body.appendChild(style)
    })
}

function removeScript(id) {
    const script = document.getElementById(id)

    if (script) {
        script.parentNode.removeChild(script)
    }
}

const addBasketfulScript = async () => {
    removeScript("basketful-script")
    await addScript("https://shop.basketful.co/basketful.js", "basketful-script")
}

const addStoreLocatorScript = async () => {
    removeScript("storelocator-script")
    await addScript("https://ss.click2cart.com/assets/js/storelocator/storelocator_min.js", "storelocator-script")
}

const addSmartCardScript = async () => {
    removeScript("smartcard-script")
    await addScript("https://ss.click2cart.com/assets/js/smartcart_min.js", "smartcard-script")
    await addScript(
        "https://s3-us-west-2.amazonaws.com/static.smartcommerce.co/assets/client/TCU4CR/js/smartcart_snackworks_us_prod.js",
        "smartcard-snackworks-script"
    )
}

window.document.addBasketfulScript = addBasketfulScript
window.document.addStoreLocatorScript = addStoreLocatorScript
window.document.addSmartCardScript = addSmartCardScript

export const onClientEntry = async () => {
    await addScript("https://2cart.net/d2c/js/jquery.min.js", "jquery-script")
    await addStyle(
        "https://s3-us-west-2.amazonaws.com/static.smartcommerce.co/assets/client/TCU4CR/css/smartcart_snackworks_us_prod.css", // Not ready
        "smartcart_snackworks_ca_prod-style"
    )

    try {
        await addScript("https://c.lytics.io/static/pathfora.min.js")
    } catch (e) {
        console.error(e)
    }
}

export const onRouteUpdate = async ({ location, prevLocation }) => {
    // hack to check if previous page was the same (inner linking) so the animation can be triggered
    window.locations = window.locations || [document.referrer]
    locations.push(window.location.href)
    window.previousPath = locations[locations.length - 1]
    addSmartCardScript()

    if (location.pathname.includes("/product")) {
        addBasketfulScript()
    }
}
